import React from 'react';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Header from '../../../components/Header/navmap/landingFleet';
import i18n from '../../../locale';
import sitemap from '../../../data/sitemap';
import Hero from './Hero';
import Vehicles from './Vehicles';
import Fleet from './Fleet';
import DriverApp from './DriverApp';
import FAQ from './FAQ';

export default ({ seo, location }) => (
  <Layout overlay 
    page={sitemap.landingFleet.features}
    header={Header}
    isLanding={'fleet'}
    url='_url:landing-fleet-features'
    homeLink={i18n('_url:landing-fleet')}>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Vehicles/>
    <Fleet/>
    <DriverApp/>
    <FAQ/>
  </Layout>
);